import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../../service/index';
import { IUserModel } from '../../../provider';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'auth-user-fab-menu',
  templateUrl: './user-fab-menu.component.html',
  styleUrls: ['./user-fab-menu.component.scss']
})
export class UserFabMenuComponent implements OnInit {
  public user: IUserModel;

  constructor(
    private authService: AuthenticationService,
    private router: Router
  ) {
    authService.userChange.subscribe((user) => {
      this.user = user && user.id !== null ? user : null;
    });
    this.user = authService.currentUser && authService.currentUser.id !== null ?  authService.currentUser : null;
  }

  ngOnInit() {
  }

  public login() {
    this.authService.authenticate(this.router.url);
  }

  public logout() {
    this.authService.logout();
  }
}

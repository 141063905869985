import { Inject, Injectable } from '@angular/core';
import { IInMemoryUserModel } from './IInMemoryUserModel';
import { BaseAuthenticationProvider } from '../../base/BaseAuthenticationProvider';
import { ILoginAuthenticationProvider, ILoginConfig } from '../../IAuthenicationProvider';
import { ISimpleLoginCredentials, IAuthenticatedUser } from '../../model/index';
import { IN_MEMORY_USERS } from './token';

@Injectable()
export class InMemoryAuthenticationProvider2 extends BaseAuthenticationProvider<IAuthenticatedUser> implements
    ILoginAuthenticationProvider<IAuthenticatedUser, ISimpleLoginCredentials> {
    public static readonly PROVIDER_ID = 'in-memory2';

    public readonly providerId = InMemoryAuthenticationProvider2.PROVIDER_ID;
    public readonly providerName = 'In Memory 2';
    public readonly type: 'login' = 'login';

    constructor(
        @Inject(IN_MEMORY_USERS) private users: IInMemoryUserModel[]
    ) {
        super();
    }

    public get config(): ILoginConfig {
        return {
            supportsPasswordRecovery: true,
            usernameLabel: 'Email',
            usernamePlaceholder: 'Enter your email'
        };
    }

    logout(): Promise<void> {
        throw new Error('Method not implemented.');
    }

    login(cred?: ISimpleLoginCredentials): Promise<IAuthenticatedUser> {
        throw new Error('Method not implemented.');
    }

}

import {
  Component, OnInit, Input, ChangeDetectionStrategy, OnChanges, Output, EventEmitter,
  SimpleChanges, ViewChild, AfterViewInit, HostBinding, ChangeDetectorRef, OnDestroy,
  QueryList, ViewChildren, ElementRef, Optional, SkipSelf, HostListener
} from '@angular/core';
import { RouterLinkActive, Router, NavigationEnd } from '@angular/router';
import { IMenuDescriptor, MenuAction, isURLAction, isRouteAction, IURLAction, IRouteAction } from '../menu-descriptor/IMenuDescriptor';
import { MenuComponent, MenuLayout } from '../menu.component';
import { Subscription, merge, Observable } from 'rxjs';
import { delay, bufferWhen, map } from 'rxjs/operators';
import { SubnavMode } from './SubnavMode';
import {
  Overlay, OverlayPositionBuilder, OriginConnectionPosition,
  HorizontalConnectionPos, VerticalConnectionPos, OverlayRef, OverlayConnectionPosition
} from '@angular/cdk/overlay';
import { Portal, TemplatePortal } from '@angular/cdk/portal';




@Component({
  selector: 'ngwc-menu-item',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush

})
export class MenuItemComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  private _isActive: boolean = false;
  private _isChildActive: boolean = false;
  private _children: Promise<any[]>;
  private _routerChangeEvents: Subscription;
  private _activeChildChangeEvents: Subscription;
  private _activeGrandchildChangeEvents: Subscription;
  private _childChangeEvents: Subscription;
  private _isBranch: Promise<boolean>;

  private _subnavModes: SubnavMode | SubnavMode[] = SubnavMode.POPUP;
  private _effectiveSubnavMode: SubnavMode = SubnavMode.POPUP;
  private _childSubnavModes: SubnavMode[] = [SubnavMode.POPUP];

  public SubnavMode = SubnavMode;

  @ViewChild(RouterLinkActive)
  private routerLinkActive: RouterLinkActive;

  @Input()
  public item: any;

  @Input()
  public showIcons: boolean = false;

  @Input()
  @HostBinding('attr.layout')
  public layout: MenuLayout = MenuLayout.VERTICAL;

  @Input()
  @HostBinding('attr.childLayout')
  public childLayout: MenuLayout = MenuLayout.VERTICAL;

  @Input()
  public get subnavMode(): SubnavMode | SubnavMode[] {
    return this._subnavModes;
  }

  public set subnavMode(value: SubnavMode | SubnavMode[]) {
    this._subnavModes = value;
    if (Array.isArray(value)) {
      this._effectiveSubnavMode = value[0];
      if (value.length > 1) {
        this._childSubnavModes = value.slice(1);
      } else {
        this._childSubnavModes = value;
      }
    } else {
      this._effectiveSubnavMode = value;
      this._childSubnavModes = [value];
    }
  }

  public get effectiveSubnavMode(): SubnavMode {
    return this._effectiveSubnavMode;
  }

  public get childSubvavMode(): SubnavMode[] {
    return this._childSubnavModes;
  }


  @ViewChildren(MenuItemComponent)
  private childComponents: QueryList<MenuItemComponent>;

  @Input()
  public opened: boolean = false;

  public get label(): string {
    return this.itemDescriptor.getLabel(this.item, this.rootItem);
  }

  public get icon(): string {
    return this.itemDescriptor.getIcon(this.item, this.rootItem);
  }


  public get actionURL(): IURLAction {
    let action = this.action;
    if (action) {
      if (typeof action === 'string') {
        return {
          'url': action,
          'target': '_balnk'
        };
      }
      if (isURLAction(action)) {
        if (!action.target) {
          action = Object.assign({}, action);
          action.target = '_blank';
        }
        return action;
      }
    }
  }

  public get actionRoute(): IRouteAction {
    let action = this.action;
    if (action) {
      if (Array.isArray(action)) {
        return {
          commands: action,
          options: {}
        };
      }
      if (isRouteAction(action)) {
        if (!action.options) {
          action = Object.assign({}, action);
          action.options = {};
        }
        return action;
      }
    }
  }
  private get action(): MenuAction {
    return this.itemDescriptor.getAction(this.item, this.rootItem);
  }

  public get actionType(): 'url' | 'route' | 'function' | undefined {
    const action = this.action;
    if (action) {
      if (typeof action === 'function') {
        return 'function';
      }
      if (isURLAction(action) || typeof action === 'string') {
        return 'url';
      }
      if (isRouteAction(action) || Array.isArray(action)) {
        return 'route';
      }
    }
    return undefined;
  }


  public get isIndex(): boolean {
    return this.itemDescriptor.isIndex(this.item, this.rootItem);
  }

  public get children(): Promise<any[]> {
    return this._children;
  }

  public get isBranch(): Promise<boolean> {
    return this._isBranch;
  }

  public get itemDescriptor(): IMenuDescriptor<any> {
    return this.menuComponent.itemDescriptor;
  }

  public get rootItem(): any | any[] {
    return this.menuComponent.menuItems;
  }

  @HostBinding('class.active')
  public get isActive(): boolean {
    return this._isActive;
  }

  @HostBinding('class.child-active')
  public get isChildActive(): boolean {
    return this._isChildActive;
  }



  @ViewChild('menuPopup')
  private menuPopupTpl: TemplatePortal<any>;
  private popppMenuOverlay: OverlayRef;

  @Output()
  public activeChange: EventEmitter<boolean> = new EventEmitter();

  @Output()
  public activeChildChange: EventEmitter<boolean> = new EventEmitter();

  private getIsActive(): boolean {
    return this.routerLinkActive ? this.routerLinkActive.isActive : false;
  }
  private setIsActive(value: boolean): void {
    if (this._isActive !== value) {
      this._isActive = value;
      this.changeDetectorRef.markForCheck();
      this.activeChange.emit(value);
    }
  }

  private getIsChildActive(): boolean {
    return !!this.childComponents.find(a => a.isChildActive) || !!this.childComponents.find(b => b.isActive);
  }

  private setIsChildActive(value: boolean): void {
    if (this._isChildActive !== value) {
      this._isChildActive = value;
      this.changeDetectorRef.markForCheck();
      if (value && !this.popppMenuOverlay) {
        this.open();
      }
    }
    this.activeChildChange.emit(value);
  }

  constructor(
    private menuComponent: MenuComponent,
    @Optional() @SkipSelf() private parentItem: MenuItemComponent,
    private changeDetectorRef: ChangeDetectorRef,
    private router: Router,
    private overlayPositionBuilder: OverlayPositionBuilder,
    private overlay: Overlay,
    private elmRef: ElementRef
  ) {

    this._routerChangeEvents = router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        setTimeout(() => {
          this.setIsActive(this.getIsActive());
        }, 0);
      }
    });
  }
  @HostListener('click', ['$event'])
  public handleClick(event: MouseEvent | TouchEvent) {
    event.stopPropagation();
  }
  @HostListener('document:click', ['$event'])
  public handleClickOutside(event: MouseEvent | TouchEvent) {
    if (this.effectiveSubnavMode === SubnavMode.POPUP) {
      this.close();
    }
  }


  public get depth(): number {
    if (!this.parentItem) {
      return 0;
    }
    return this.parentItem.depth + 1;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.item) {
      this._children = this.itemDescriptor.getChildren(this.item, this.rootItem);
      this._isBranch = this.itemDescriptor.isBranch(this.item, this.rootItem);
    }
    this.createPopup();
    setTimeout(() => {
      this.close();
    }, 0);
  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    this.addChildActiveListeners();
    this._childChangeEvents = this.childComponents.changes.subscribe(() => {
      this.addChildActiveListeners();
    });
    setTimeout(() => {
      this.setIsActive(this.getIsActive());
    }, 0);

    this.createPopup();
  }




  private createPopup(): void {
    this.removePopup();
    if (this.menuPopupTpl) {

      const originPos: OriginConnectionPosition = {
        originX: 'end',
        originY: 'top'
      };

      const overlayPos: OverlayConnectionPosition = {
        overlayX: 'start',
        overlayY: 'top'
      };
      if (this.layout === MenuLayout.HORIZONTAL) {
        originPos.originX = 'start';
        originPos.originY = 'bottom';
      }

      this.popppMenuOverlay = this.overlay.create({
        hasBackdrop: false,
        positionStrategy: this.overlayPositionBuilder.connectedTo(this.elmRef, originPos, overlayPos)
      });
      this.popppMenuOverlay.attach(this.menuPopupTpl);
    }
  }

  private removePopup(): void {
    if (this.menuPopupTpl && this.menuPopupTpl.isAttached) {
      this.menuPopupTpl.detach();
    }
    if (this.popppMenuOverlay) {
      this.popppMenuOverlay.dispose();
      this.popppMenuOverlay = undefined;
    }
  }

  private addChildActiveListeners() {
    this.removeChildActiveListeners();
    const childChange: Observable<boolean>[] = [];
    this.childComponents.forEach(child => {
      childChange.push(child.activeChange.asObservable());
      childChange.push(child.activeChildChange.asObservable());
    });

    const childChangeSet = merge(...childChange);

    const defered = childChangeSet.pipe(bufferWhen(() => childChangeSet.pipe(delay(1))));
    this._activeChildChangeEvents = defered.subscribe((value) => {
      this.setIsChildActive(this.getIsChildActive());
    });
  }

  private removeChildActiveListeners() {

    if (this._activeChildChangeEvents) {
      this._activeChildChangeEvents.unsubscribe();
      this._activeChildChangeEvents = undefined;
    }
    if (this._activeGrandchildChangeEvents) {
      this._activeGrandchildChangeEvents.unsubscribe();
      this._activeGrandchildChangeEvents = undefined;
    }

  }

  ngOnDestroy(): void {
    if (this._routerChangeEvents) {
      this._routerChangeEvents.unsubscribe();
    }
    if (this._childChangeEvents) {
      this._childChangeEvents.unsubscribe();
    }
    this.removeChildActiveListeners();
    this.removePopup();
  }

  public open(event?: MouseEvent) {
    if (this.parentItem) {
      this.parentItem.closeChildren(this);
    }

    this.opened = true;
    this.changeDetectorRef.markForCheck();
    if (this.popppMenuOverlay) {
      this.popppMenuOverlay.updatePosition();
    }
    if (event) {
      event.preventDefault();
      event.stopImmediatePropagation();
    }
  }

  public close(event?: MouseEvent) {
    this.opened = false;
    this.closeChildren();
    this.changeDetectorRef.markForCheck();
    if (this.popppMenuOverlay) {
      this.popppMenuOverlay.updatePosition();
    }
    if (event) {
      event.preventDefault();
      event.stopImmediatePropagation();
    }
  }

  public closeChildren(except?: MenuItemComponent) {
    this.childComponents.forEach(child => {
      if (child !== except) {
        child.close();
      }
    });
  }

  public toogle(event?: MouseEvent) {
    if (this.opened) {
      this.close();
    } else {
      this.open();
    }
    if (this.popppMenuOverlay) {
      this.popppMenuOverlay.updatePosition();
    }
    if (event) {
      event.preventDefault();
      event.stopImmediatePropagation();
    }
  }
}
